<template>
  <div class="show_score">
    <StuScore title="战三" des="30"></StuScore>
    <el-table :data="scores" style="width: 100%">
      <el-table-column label="班级">
        <template slot-scope="scope">
          <span>{{ scope.row.c_class.c_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="讲师">
        <template slot-scope="scope">
          <span>{{ scope.row.c_class.tea }}</span>
        </template>
      </el-table-column>
      <el-table-column label="学生">
        <template slot-scope="scope">
          <span>{{ scope.row.s_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成绩">
        <template slot-scope="scope">
          <span>{{ scope.row.s_score }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import StuScore from "@/components/StuScore.vue";
export default {
  name: "ShowScore",
  data() {
    return {
      scores: [],
    };
  },
  async created() {
    this.$datas.meta = this.$route.query.meta;
    let res = await this.$datas.getScores;
    this.scores = res.data.data;
  },
  components: {
    StuScore,
  },
};
</script>
<style lang="less">
.show_score {
  padding: 10px;
}
</style>
